import React from 'react'
import { Dropdown } from 'react-bootstrap-v5'
import { QuoteModel } from '../../../../types'

type Props = {
  data: QuoteModel
  handleUpdatePayment: (quoteId: number) => void
}

const ActionDropDown: React.FC<Props> = (props) => {
  const { data, handleUpdatePayment } = props
  return (
    <Dropdown key={data.dbkey}>
      <Dropdown.Toggle id="dropdown-basic" variant="light" size="sm">
        Actions
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          href={`${process.env.REACT_APP_NBS_ONLINE_URL}processing_pdf.php?dbkey_quote_b=${data.dbkey}&dbkey_calreports=&order_type=quote&pdf=y`}
          target="_blank"
        >
          View
        </Dropdown.Item>
        <Dropdown.Item href='#' onClick={() => handleUpdatePayment(data.dbkey)}>
          Update Payment Method
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { ActionDropDown }