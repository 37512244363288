import { Action } from '@reduxjs/toolkit'
import { put, takeLatest } from "redux-saga/effects"
import { NotificationModel } from '../../../types'
import { getUserNotifications } from '../../services/notification'

export interface ActionWithPayload<T> extends Action {
  payload? : T
}

export const actionTypes = {
  NotificationsRequested: "[Request Notifications] Action",
  NotificationsLoaded: "[Load Notifications] Action",
};

const initialNotificationsState: INotificationsState = {
  loading: false,
  notifications: []
};

export interface INotificationsState {
  loading: boolean
  notifications: any
}

export const reducer = (
  state: INotificationsState = initialNotificationsState, 
  action: ActionWithPayload<INotificationsState>
) => {
  switch (action.type) {
    case actionTypes.NotificationsRequested: {
      return {...state, loading: true}
    }
    case actionTypes.NotificationsLoaded: {
      const notifications = action.payload?.notifications
      return { loading: false, notifications}
    }

    default:
      return state;
  }
}


export const actions = {
  getUserNotifications: (filter: any = {}) => ({ 
    type: actionTypes.NotificationsRequested, 
    payload: { filter } 
  }),
  notificationsLoaded: (notifications: NotificationModel[]) => ({ 
    type: actionTypes.NotificationsLoaded, 
    payload: { notifications } 
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.NotificationsRequested, function* NotificationsRequested(action: any) {
    const { filter } = action.payload
    const { data: notifications } = yield getUserNotifications(filter)

    yield put(actions.notificationsLoaded(notifications))
  });
}
