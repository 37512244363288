import React, { useState, useEffect } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { RootState } from "../../../../setup"
import { UserModel } from "../../../../types"
import getContactEmail from "./footerCrud"
import * as levelHelper from '../../../../helper/level.helper'

const ContactLink = () => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [mailToEmail, setMailtoEmail] = useState<string>("mailto: jesse@threesdesign.net")

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  
  useEffect(() => {
    getContactEmail()
    .then((res) => {
      setMailtoEmail(res.data)
    }).catch((res) => {
      setMailtoEmail("mailto: jesse@threesdesign.net");
    })

    setIsAdmin(levelHelper.isNBS(user?.type))
  }, [user])
  return (
    <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
      <li className='menu-item'>
          <a href={isAdmin ? mailToEmail : '/contact-us'} id="mailToId" className='menu-link pe-0 pe-2'>
            Contact
          </a>
      </li>
    </ul>
  )
}

export default ContactLink;
