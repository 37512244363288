/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import {FC, useState, useEffect} from 'react'
import toast from 'react-hot-toast'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {getErrorMessage} from '../../../helper/response.helper'
import {RootState} from '../../../setup'
import {NotificationModel, UserModel} from '../../../types'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {markAsReadNotification} from '../../services/notification'
import {Notification} from './components'
import * as notificationsRedux from './NotificationsRedux'

const HeaderNotifications: FC = () => {
  const [notifications, setNotifications] = useState<NotificationModel[]>([])
  const [unReadCount, setUnreadCount] = useState<number>(0)
  const dispatch = useDispatch()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const notificationsSelector = useSelector<RootState>((state) => state.notifications.notifications) as any

  const handleMarkRead = (notification: NotificationModel) => {
    markAsReadNotification(notification)
      .then((res) => {
        getNotifications()
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
  }

  const getNotifications = () => {
    dispatch(notificationsRedux.actions.getUserNotifications(user.id))
  }

  useEffect(() => {
    if (notificationsSelector.length !== 0) {
      setNotifications(notificationsSelector.notifications as NotificationModel[])
    }
  }, [notificationsSelector])

  useEffect(() => {
    getNotifications()
  }, [])

  useEffect(() => {
    let count = 0

    if (notifications.length !== 0) {
      notifications.forEach((notification: NotificationModel) => {
        if (!notification.read_at) count++
      })
    }
    setUnreadCount(count)
      
  }, [notifications])

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
          Notifications <span className='fs-8 opacity-75 ps-3'>{unReadCount} unread</span>
        </h3>
      </div>

      <div className='tab-content'>
        <div
          className='tab-pane overflow-auto h-50vh show active'
          id='kt_topbar_notifications_2'
          role='tabpanel'
        >
          <div className=' pb-0'>
            {notifications.map((notification, index) => {
              return (
                <Notification
                  key={index}
                  index={index}
                  notification={notification}
                  markRead={handleMarkRead}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export {HeaderNotifications}
