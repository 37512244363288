/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */

import React, { FC, useEffect, useMemo, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Card } from 'react-bootstrap-v5'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTSVG } from '../../../_metronic/helpers'
import { IAuthState } from '../../modules/auth'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import { CalibrationReportModel } from '../../../types/calibration-reports/CalibrationReportModel'
import { RootState } from '../../../setup'
import { TableColumn } from 'react-data-table-component'
import moment from 'moment'
import DataTableBase from '../../components/DataTableBase'
import FilterDropDown from '../../modules/calibration/FilterDropDown'
import * as calibrationReportsRedux from './calibrationReportsRedux'
import * as levelHelper from '../../../helper/level.helper'

interface ReduxStateValueProps {
  calibrationReports: CalibrationReportModel[]
  loading: boolean
  activeCustomerId: string | undefined
  auth: IAuthState & PersistPartial
}

const mapState = (state: RootState) => ({
  calibrationReports: state.calibrationReports.calibrationReports,
  loading: state.calibrationReports.loading,
  activeCustomerId: state.auth?.activeCustomerId,
  auth: state.auth,
})

const connector = connect(mapState, {...calibrationReportsRedux.actions})

type PropsFromRedux = ConnectedProps<typeof connector>
type CalibrationReportsProps = ReduxStateValueProps & PropsFromRedux

const CalibrationReportsPage: FC<CalibrationReportsProps> = (props) => {
  const {getCalibrationReports, calibrationReports, activeCustomerId, auth} = props
  const initialFilter = {
    from: '',
    to: moment().utc().format("Y-MM-DD"),
    isReset: false
  }

  const [isNBS, setIsNBS] = useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>('')

  const handleFilter = (filters: any) => {
    const { customerId, from, to, isReset } = filters;

    if (isReset === true) {
      getCalibrationReports(
        isNBS ? 'nbs' : activeCustomerId,
        {
          from: initialFilter.from,
          to: initialFilter.to
        }
      )
    }
    else {
      getCalibrationReports(
        isNBS ? customerId === '' ? 'nbs' : customerId : activeCustomerId,
        {
          from: from,
          to: to
        }
      )
    }
  }

  useEffect(() => {
    setIsNBS(levelHelper.isNBS(auth.user?.type))

    getCalibrationReports(
      levelHelper.isNBS(props.auth.user?.type) ? 'nbs' : activeCustomerId,
      {
        from: initialFilter.from,
        to: initialFilter.to
      }
    )
  }, [getCalibrationReports, activeCustomerId, auth])

  const columns: TableColumn<CalibrationReportModel>[] = useMemo(
    () => [
      {
        name: 'Customer ID',
        selector: (row: CalibrationReportModel) => row.customerid,
        cell: (row: CalibrationReportModel) => <span>{row.customerid}</span>,
        sortable: true,
        style: {
          maxWidth: 'unset !important',
        },
      },
      {
        name: 'Date of Calibration',
        selector: (row: CalibrationReportModel) => row.dateofcal,
        cell: (row: CalibrationReportModel) => (
          <span>{moment(row.dateofcal).format('MM/DD/YY')}</span>
        ),
        sortable: true,
        style: {
          maxWidth: 'unset !important',
        },
      },
      {
        name: 'Customer Name',
        selector: (row: CalibrationReportModel) => row.customer,
        cell: (row: CalibrationReportModel) => <span>{row.customer}</span>,
        sortable: true,
        style: {
          maxWidth: 'unset !important',
        },
      },
      {
        name: 'Technician',
        selector: (row: CalibrationReportModel) => row.technician_fullname,
        cell: (row: CalibrationReportModel) => <span>{row.technician_fullname}</span>,
        sortable: true,
        style: {
          maxWidth: 'unset !important',
        },
      },
      {
        name: 'MFG',
        selector: (row: CalibrationReportModel) => row.manufacturer,
        cell: (row: CalibrationReportModel) => <span>{row.manufacturer}</span>,
        sortable: true,
        style: {
          maxWidth: 'unset !important',
        },
      },
      {
        name: 'Model',
        selector: (row: CalibrationReportModel) => row.model,
        cell: (row: CalibrationReportModel) => <span>{row.model}</span>,
        sortable: true,
        style: {
          maxWidth: 'unset !important',
        },
      },
      {
        name: 'Serial #',
        selector: (row: CalibrationReportModel) => row.serial,
        cell: (row: CalibrationReportModel) => <span>{row.serial}</span>,
        sortable: true,
        style: {
          maxWidth: 'unset !important',
        },
      },
      {
        name: 'Equipment Type',
        selector: (row: CalibrationReportModel) => row.equipment_description,
        cell: (row: CalibrationReportModel) => <span>{row.equipment_description}</span>,
        sortable: true,
        style: {
          maxWidth: 'unset !important',
        },
      },
    ],
    []
  )

  return (
    <>
      <PageTitle breadcrumbs={[]}>Calibration Reports</PageTitle>
      <Card className='min-vh-100'>
        <Card.Header className='border-0 pt-6'>
          <Card.Title>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-customer-table-filter='search'
                className='form-control form-control-solid w-250px ps-15 border-0'
                placeholder='Search Calibration Reports'
                value={keyword}
                onChange={(e: any) => setKeyword(e.target.value)}
              />
            </div>
          </Card.Title>
          <div className="card-toolbar">
            <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
              <div className='filter-dropdown ms-3'>
                <button
                  type="button"
                  className="btn btn-light-primary me-3"
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Filter options'
                >
                  <KTSVG
                    path='/media/icons/duotone/Text/Filter.svg'
                    className='svg-icon-2 text-light'
                  />
                  Filter
                </button>
                <FilterDropDown handleFilter={handleFilter} />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body className='pt-0 calibration-reports-body'>
          <DataTableBase columns={columns} data={calibrationReports} />
        </Card.Body>
      </Card>
    </>
  )
}

export default connector(CalibrationReportsPage)
