import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import { SelectDropDown } from '../../../components/SelectDropDown'
import * as helper from '../../../../helper'

type Props = {
  show: boolean
  onHide: any
  title: string
  option: any
  submit: boolean
  onCreate: any
}

export const AddCardModal: React.FC<Props> = (props) => {
  const { show, onHide, title, option, submit, onCreate } = props
  const [validateCardName, setValidateCardName] = useState<boolean>(true)
  const [validateCardNumber, setValidateCardNumber] = useState<boolean>(true)
  const [validateExpireMonth, setValidateExpireMonth] = useState<boolean>(true)
  const [validateExpireYear, setValidateExpireYear] = useState<boolean>(true)
  const [validateCVV, setValidateCVV] = useState<boolean>(true)
  const [isAdd, setIsAdd] = useState<boolean>(true)
  const [cardType, setCardType] = useState<string>('')
  const [month, setMonth] = useState<string | number>('Month')
  const [year, setYear] = useState<string | number>('Year')
  const [cardName, setCardName] = useState<string>('')
  const [cardNumber, setCardNumber] = useState<string>('')
  const [maskedCardNumber, setMaskedCardNumber] = useState<string>('')
  const [cvv, setCVV] = useState<string>('')
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09','10', '11', '12']
  const years = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032]

  const handleSubmit = () => {
    if (
      !validateCardName     ||
      !validateCardNumber   ||
      !validateExpireMonth  ||
      !validateExpireYear   ||
      !validateCVV          ||
      cardName === ''       ||
      month === 'Month'     ||
      year === 'Year'       ||
      cvv === ''
    ) {
      return
    }

    const payload = {
      cardName: cardName,
      cardNumber: cardNumber.replace(/\s/g, ''),
      expireMonth: month,
      expireYear: year,
      cvv: cvv.trim(),
      profileId: isAdd ? '' : option?.customerPaymentProfileId,
      isAdd 
    }
    
    onCreate(payload)
  }

  const handleCardName = (e: any) => {
    setCardName(e.target.value)

    if (e.target.value === '')
      setValidateCardName(false)

    const result = helper.cardNameValidator(e.target.value)

    setValidateCardName(result.isValid)
    setCardName(e.target.value)
  }

  const handleCardNumber = (e: any) => {
    setCardNumber(e.target.value)

    if (e.target.value === '')
      setValidateCardNumber(false)

    const result = helper.cardNumberValidator(e.target.value)

    setValidateCardNumber(result.isValid)
    setCardType(result.cardType)
  }

  const handleCardMonth = (value: string | number) => {
    setMonth(value)
    console.log(value)
    if (value === 'Month')
      setValidateExpireMonth(false)

    const result = helper.cardMonthValidator(value)
    setValidateExpireMonth(result.isValid)
  }

  const handleCardYear = (value: string | number) => {
    setYear(value)

    if (value === 'Year')
      setValidateExpireYear(false)
  }

  const handleCVV = (e: any) => {
    setCVV(e.target.value)

    if (e.target.value === '')
      setValidateCVV(false)
      
    const result = helper.cardCVVValidator(e.target.value)
    setValidateCVV(result.isValid)
  }

  useEffect(() => {
    const addOrEdit = title === 'Add New Card' ? true : false

    setIsAdd(addOrEdit)

    if (addOrEdit) {
      setCardType('')
      setCardName('')
      setCardNumber('')
      setMaskedCardNumber('')
      setYear('Year')
      setMonth('Month')
    } else {
      const expireDate =option?.payment.creditCard?.expirationDate?.split('-')

      setCardName(option?.billTo?.address)
      setMaskedCardNumber(option?.payment.creditCard?.cardNumber)
      setYear(expireDate[0])
      setMonth(expireDate[1])
    }

  }, [title, option])

  return (
    <Modal size='lg' 
      show={show}
      dialogClassName="w-50"
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title className='w-100' id="contained-modal-title-vcenter">
          <div className='d-flex justify-content-between'>
            <h2>{ title }</h2>
            <span className='text-hover-primary cursor-pointer' onClick={onHide}>
              <KTSVG
                path='/media/icons/duotone/Navigation/Close.svg'
                className='svg-icon-1'
              />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-10 pb-15 px-lg-17'>
        <div className='d-flex flex-column scroll-y me-n7 pe-7 max-height-60'>
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>
              <span className='required'>Name On Card</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Specify a name of card holder'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-solid'
              placeholder='card name'
              onChange={handleCardName}
              value={cardName}
            />
            {
              !validateCardName &&
              <div className='fv-plugins-message-container invalid-feedback'>
                {
                  cardName === '' ? 'Name on card is required' : 'Name on card is not valid'
                }
              </div>
            }
          </div>
          <div className='fv-row mb-7'>
            <div>
              <label className='fw-bold fs-6 mb-2 pt-1 required'>Card Number</label>
              {
                !isAdd &&
                <span> - { maskedCardNumber }</span>
              }
            </div>
            <div className='position-relative'>
              <input
                type='text'
                className='form-control form-control-solid'
                placeholder='Enter card number'
                name='card_number'
                onChange={handleCardNumber}
                value={cardNumber}
              />
              <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                <img
                  src='/media/icons/duotone/Shopping/visa.svg'
                  className={`h-25px ${cardType === 'visa' && 'border border-dark'}`}
                  alt='visa'
                ></img>
                <img
                  src='/media/icons/duotone/Shopping/mastercard.svg'
                  className={`h-25px ${cardType === 'mastercard' && 'border border-dark'}`}
                  alt='master'
                ></img>
                <img
                  src='/media/icons/duotone/Shopping/american-express.svg'
                  className={`h-25px ${cardType === 'american-express' && 'border border-dark'}`}
                  alt='american'
                ></img>
              </div>
            </div>
            {
              !validateCardNumber &&
              <div className='fv-plugins-message-container invalid-feedback'>
                <div>
                  {
                    cardNumber === '' ? 'Name on card is required' : 'Card number is not valid'
                  }
                </div>
              </div>
            }
          </div>
          <div className='row mb-10'>
            <div className='col-md-8 fv-row'>
              <label className='required fs-6 fw-bold form-label mb-2'>Expiration Date</label>
              <div className='row fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid'>
                <div className='col-6'>
                  <SelectDropDown data={months} defaultValue={month} dirUp={true} selectedValue={handleCardMonth} />
                </div>
                <div className='col-6'>
                  <SelectDropDown data={years} defaultValue={year} dirUp={true} selectedValue={handleCardYear} />
                </div>
              </div>
              {
                (!validateExpireMonth || !validateExpireYear) &&
                <div className='fv-plugins-message-container invalid-feedback'>
                  <div>
                    {
                      (month === 'Month' || year === 'Year') ? 'Expiration date is required' : 'Expiration date is not valid'
                    }
                  </div>
                </div>
              }
            </div>
            <div className='col-md-4 fv-row fv-plugins-icon-container'>
              <label className='fs-6 fw-bold form-label mb-2'>
                <span className='required'>CVV</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Enter a card CVV code'
                ></i>
              </label>
              <div className='position-relative'>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  minLength={3}
                  maxLength={4}
                  placeholder='CVV'
                  onChange={handleCVV}
                />
                <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                  <KTSVG
                    path='/media/icons/duotone/Shopping/fin002.svg'
                    className='svg-icon-2hx'
                  />
                </div>
              </div>
              {
                !validateCVV &&
                <div className='fv-plugins-message-container invalid-feedback'>
                  <div>
                    {
                      cvv === '' ? 'CVV on Card is required' : 'CVV on Card is not valid'
                    }
                    </div>
                </div>
              }
            </div>
          </div>
          <div className='d-flex flex-stack'>
            <div className='me-5'>
              <label className='fs-6 fw-bold form-label'>Save Card for further billing?</label>
              <div className='fs-7 fw-bold text-muted'>If you need more info, please check budget planning</div>
            </div>
            <div className='form-check form-switch form-switch form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                name='notifications'
                defaultChecked={true}
              />
              <label className='form-check-label'>Save Card</label>
            </div>
          </div>
        </div>
        <div className='text-center pt-15'>
          <button
            type='button'
            className='btn btn-light me-3'
            onClick={() => onHide(true)}
          >
            Discard
          </button>
          <button
            type='submit'
            className={`btn ${submit ? 'btn-light' : 'btn-primary'}`}
            onClick={handleSubmit}
          >
            Submit
            {
              submit &&
              <div
                className="spinner-border w-17px h-17px ms-2 text-primary"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            }
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
