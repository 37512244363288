import React from 'react'
import { Dropdown } from 'react-bootstrap-v5'
import { QuoteModel } from '../../../../types'

type Props = {
  data: QuoteModel
  handleReject: () => void
  handleComment: () => void
  handleApprove: () => void
}

const ActionDropDown: React.FC<Props> = (props) => {
  const { data, handleReject, handleComment, handleApprove } = props
  return (
    <Dropdown key={data.dbkey}>
      <Dropdown.Toggle id="dropdown-basic" variant="light" size="sm">
        Actions
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          href={`${process.env.REACT_APP_NBS_ONLINE_URL}processing_pdf.php?dbkey_quote_b=${data.dbkey}&dbkey_calreports=&order_type=quote&pdf=y`}
          target="_blank"
        >
          View
        </Dropdown.Item>
        {
          props.data.status === "Active" &&
          <Dropdown.Item href="#" onClick={handleReject}>Reject</Dropdown.Item>
        }
         <Dropdown.Item href="#" onClick={handleComment}>Comment</Dropdown.Item>
        {
          props.data.status === "Active" &&
          <Dropdown.Item href="#" onClick={handleApprove}>Approve</Dropdown.Item>
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}

export { ActionDropDown }