import axios from "axios"

export const APP_URL = process.env.REACT_APP_API_URL

export async function requestServiceNow(payload: any) {
  const Service_URL = `${APP_URL}/service-now/request`

  return axios.post(Service_URL, { payload });
}

export async function requestContactEmail(payload: any) {
  const Service_URL = `${APP_URL}/contact-us`

  return axios.post(Service_URL, { payload });
}

export async function getCRFToken() {
  const Service_URL = `${APP_URL}/nbs-admins/token`

  return axios.get(Service_URL);
}
