import {Action} from '@reduxjs/toolkit'
import {put, takeLatest} from 'redux-saga/effects'
import {CalibrationReportModel} from '../../../types/calibration-reports/CalibrationReportModel'
import {getCalibrationReports} from './calibrationReportsCrud'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  GetCalibrationReports: '[GetCalibrationReports] Action',
  GetCalibrationReportsSuccess: '[GetCalibrationReportsSuccess] Action',
}

export interface ICalibrationReportState {
  calibrationReports: any
  loading: boolean
}

const initialQuotesState: ICalibrationReportState = {
  calibrationReports: [],
  loading: false,
}

export const reducer = (
  state = initialQuotesState,
  action: ActionWithPayload<ICalibrationReportState>
) => {
  switch (action.type) {
    case actionTypes.GetCalibrationReportsSuccess: {
      const calibrationReports = action.payload?.calibrationReports

      return {...state, calibrationReports}
    }
    default:
      return state
  }
}

export const actions = {
  getCalibrationReports: (customerId: string | undefined, searchFilter = {}) => ({
    type: actionTypes.GetCalibrationReports,
    payload: {customerId, searchFilter},
  }),
  getCalibrationReportsSuccess: (calibrationReports: CalibrationReportModel[]) => ({
    type: actionTypes.GetCalibrationReportsSuccess,
    payload: {calibrationReports},
  }),
}

export function* saga() {
  yield takeLatest(
    actionTypes.GetCalibrationReports,
    function* getCalibrationReportsRequested(action: any) {
      const {customerId, searchFilter} = action.payload
      const {data} = yield getCalibrationReports(customerId, searchFilter)

      yield put(actions.getCalibrationReportsSuccess(data))
    }
  )
}
