import axios from "axios"

export const APP_URL = process.env.REACT_APP_API_URL

export async function createPaymentOption(payload: any) {
  const Service_URL = `${APP_URL}/payment-option/${payload.isAdd ? 'create' : 'edit'}`

  return axios.post(Service_URL, { payload })
}

export async function getPaymentOptions() {
  const Service_URL = `${APP_URL}/payment-option/get-profiles`

  return axios.get(Service_URL)
}

export async function deletePaymentOption(paymentId: string) {
  const Service_URL = `${APP_URL}/payment-option/delete-profile/${paymentId}`

  return axios.get(Service_URL)
}

export async function updateQuotePaymentOption(payload: any) {
  const Service_URL = `${APP_URL}/quote-order/update-payment-option`

  return axios.post(Service_URL, { payload })
}
