import React, { useState, useEffect, useRef } from 'react'
import {connect, useDispatch} from 'react-redux'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import BaseModal from '../../components/_modal/BaseModal'
import QuillEditorToolbar from './QuillEditorToolbar'
import {getErrorMessage} from '../../../helper/response.helper'
import { BlogModel } from '../../../types'
import { createBlog, updateBlog } from './redux/blogCRUD'
import * as blogs from './redux/blogRedux'

type BlogFormProps = {
    values: BlogModel | null
    isEdit: boolean
    handleCancel: () => void
}

const blogSchema = Yup.object().shape({
    title: Yup.string().required('Blog title is required')
})

const BlogForm: React.FC<BlogFormProps> = (props) => {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const dispatch = useDispatch()
    const fileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        formik.setValues({
            "title": props.values?.title ?? '',
            "content": props.values?.content ?? '',
            "attachment": props.values?.attachment ?? ''
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const onChangePicHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            formik.setFieldValue('attachment', e.target.files[0])
        }
    }

    const handleSubmit = () => {        
        setLoading(true)
        if (props.isEdit) {
            updateBlog(props.values?.id, formik.values).then((res) => {
                toast.success(res.data.message)
                dispatch(blogs.actions.getBlogs({slug: null, from: null, to: null}))
            })
            .catch((error) => {
                const errMsg = getErrorMessage(error)
                toast.error(errMsg)
            })
            .finally(() => {
                setShow(false)
                setLoading(false)
                props.handleCancel()
                formik.resetForm()
            })
        } else {
            createBlog(formik.values).then((res) => {
                toast.success(res.data.message)
                dispatch(blogs.actions.getBlogs({slug: null, from: null, to: null}))
            })
            .catch((error) => {
                const errMsg = getErrorMessage(error)
                toast.error(errMsg)
            })
            .finally(() => {
                setShow(false)
                setLoading(false)
                formik.resetForm()
            })
        }
        fileRef.current!.value = ''
    }

    const formik = useFormik({
        initialValues: {
            title: '',
            content: '',
            attachment: ''
        },
        validationSchema: blogSchema,
        onSubmit: values => {
            setShow(true)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card card-xxl-stretch mb-5 mb-xl-8'>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'>Whats new at NBS editor</span>
                    </h3>
                    <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Click to publish new Blog'
                    >
                        <button type='submit' className='btn btn-primary'>
                            {props.isEdit ? 
                                (
                                    "Update"
                                ): (
                                    "Publish"
                                )
                            }
                        </button>
                        {props.isEdit && (
                            <button type='button' className='btn btn-primary ms-3' onClick={() => {
                                props.handleCancel()
                            }}>
                                Cancel
                            </button>
                        )}
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    <div className='row mb-6'>
                        <label className='w-125px col-form-label required fw-bold fs-6'>Blog Title</label>

                        <div className='flex-grow-1 w-auto fv-row'>
                            <input
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                placeholder='Blog Title'
                                {...formik.getFieldProps('title')}
                            />
                            {formik.touched.title && formik.errors.title && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.title}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <label className='w-125px col-form-label fw-bold fs-6'>Attachment</label>
                        <div className='flex-grow-1 w-auto fv-row'>
                            <input 
                                className="form-control" 
                                type="file" 
                                accept='image/*,.doc*,.ppt, .pptx,.txt,.pdf'
                                onChange={onChangePicHandler}
                                id='docUpload'
                                ref={fileRef}
                            />
                        </div>
                    </div>
                    <div className='mb-6'>
                        <QuillEditorToolbar />
                        <ReactQuill
                            theme="snow"
                            modules={{
                                toolbar: {
                                    container: "#toolbar-container"
                                }
                            }}
                            value={formik.values.content}
                            onChange={(value) => formik.setFieldValue("content", value)} />
                    </div>
                </div>
                {/* begin::Body */}
            </div>
            <BaseModal 
                id="blog_form" 
                title="Publish New Blog"
                content="This post will be published to all of your customers, are you sure you want to continue?"
                isComment={false}
                okText="Yes"
                cancelText="No"
                show={show}
                loading={loading}
                handleOk={handleSubmit}
                handleCancel={() => setShow(false)}
            />
        </form>
    )
}

const connector = connect(null, null)
export default connector(BlogForm)