import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../_metronic/helpers'
import { SelectDropDown } from '../SelectDropDown'
import { getPaymentOptions, createPaymentOption } from '../../services/payment'
import { AddCardModal } from '../../modules/accounts/components/AddCardModal'
import { AnySchema } from 'yup'
import toast from 'react-hot-toast'
import { getErrorMessage } from '../../../helper'

type Props = {
  show: boolean
  onHide: any
  title: string
  options: any
  submit: boolean
  onCreate: any
}

export const UpdatePaymentOption: React.FC<Props> = (props) => {
  const { show, onHide, title, options, submit, onCreate } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [showThisModal, setShowThisModal] = useState<boolean>(true)
  const [create, setCreate] = useState<boolean>(false)
  const [addModal, setAddModal] = useState<boolean>(false)
  const [cardNumber, setCardNumber] = useState<string | number>('')
  const [cards, setCards] = useState<string[]>([])
  const paymentOptions = ['Credit Card']

  const handleSubmit = () => {
    if (cardNumber === '') {
      return
    }

    const card = options.find((option: any) => option?.payment.creditCard?.cardNumber === cardNumber);


    const payload = {
      cardId: card?.customerPaymentProfileId
    }
    
    onCreate(payload)
  }

  const handleCardNumber = (value: string | number) => {
    setCardNumber(value)
  }

  const handleHide = () => {
    setAddModal(false)
    setShowThisModal(true)
  }

  const handleCreate = (payload: AnySchema) => {
    setCreate(true)
    createPaymentOption(payload)
    .then((res) => {
      setCreate(false)
      setAddModal(false)
      setShowThisModal(true)
      setLoading(true)

      getPaymentOptions()
      .then((res) => {
        const cardNumbers = res.data.map((option: any) => option.payment?.creditCard.cardNumber)
        setCards(cardNumbers)
        setCardNumber(cardNumbers.length === 0 ? 'No Number' : 'Select Number')
        setLoading(false)
      })
    })
    .catch((error) => {
      const errMsg = getErrorMessage(error)
      toast.error(errMsg)
    })
  }

  useEffect(() => {
    const cardNumbers = options?.map((option: any) => option.payment?.creditCard.cardNumber)
    setCards(cardNumbers)
    setCardNumber(cardNumbers?.length === 0 ? 'No Number' : 'Select Number')
  }, [title, options])

  return (
    <>
      <Modal size='lg' 
        show={show}
        dialogClassName="w-50"
        className={`${!showThisModal && 'opacity-0'}`}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title className='w-100' id="contained-modal-title-vcenter">
            <div className='d-flex justify-content-between'>
              <h2>{ title }</h2>
              <span className='text-hover-primary cursor-pointer' onClick={onHide}>
                <KTSVG
                  path='/media/icons/duotone/Navigation/Close.svg'
                  className='svg-icon-1'
                />
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-10 pb-15 px-lg-17'>
          {loading && (
            <div className='overlay-layer bg-transparent'>
              <div className='spinner spinner-lg spinner-success' />
            </div>
          )}
          <div className='d-flex flex-column scroll-y me-n7 pe-7 min-height-25 max-height-60'>
            <div className='row mb-7'>
              <div className='col-lg-6'>
                <span className='required'>
                  Credit Card Number
                </span>
                <SelectDropDown
                  data={cards}
                  defaultValue={cardNumber}
                  dirUp={false}
                  selectedValue={(value) => {
                    handleCardNumber(value)
                  }}
                />
                <div
                  className='mt-2 text-primary text-decoration-underline cursor-pointer'
                  onClick={() => {
                    setShowThisModal(false)
                    setAddModal(true)
                  }}
                >
                  Create new payment option
                </div>
              </div>
              <div className='col-lg-6'>
                <span className='required'>
                  Payment Type
                </span>
                <SelectDropDown
                  data={paymentOptions}
                  defaultValue={paymentOptions[0]}
                  dirUp={false}
                  selectedValue={(value) => {
                    handleCardNumber(value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='text-center pt-15'>
            <button
              type='button'
              className='btn btn-light me-3'
              onClick={() => onHide(true)}
            >
              Discard
            </button>
            <button
              type='submit'
              className={`btn ${submit ? 'btn-light' : 'btn-primary'}`}
              onClick={handleSubmit}
            >
              Submit
              {
                submit &&
                <div
                  className="spinner-border w-17px h-17px ms-2 text-primary"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              }
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <AddCardModal
        show={addModal}
        onHide={handleHide}
        title='Add New Card'
        option={options}
        submit={create}
        onCreate={handleCreate}
      />
    </>
  )
}
