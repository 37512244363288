import React, { useState, useRef, useEffect } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field } from 'formik'
import { KTSVG } from '../../../_metronic/helpers'
import { SelectDropDown } from '../SelectDropDown'
import { getPaymentOptions, createPaymentOption } from '../../services/payment'
import { AddCardModal } from '../../modules/accounts/components/AddCardModal'
import { getErrorMessage } from '../../../helper'
import { CONFIRM_APPROVE_CONTENT } from '../../../data/constants'
import toast from 'react-hot-toast'
import * as Yup from 'yup'

// Validation schema
const CustomerEditSchema = Yup.object().shape({
  po: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('PO is required'),
  comment: Yup.string()
    .required('Comment is required'),
})

const initialValues = {
  po: '',
  paymentType: 'po',
  hardCopyAvailable: '',
  comment: '',
  attachment: '',
}

const ApproveModal = ({ submit, status, quoteNumber, show, onHide, showConfirmAlert }) => {
  const [loading, setLoading] = useState(false)
  const [isClick, setIsClick] = useState(false)
  const [isfileSelected, setIsFileSelected] = useState(false)
  const [showThisModal, setShowThisModal] = useState(true)
  const [showAddModal, setAddModal] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [options, setOptions] = useState([])
  const [cardNumber, setCardNumber] = useState('No Number')
  const [create, setCreate] = useState(false)
  const [allFiles, setAllFiles] = useState([])

  const inputFileRef = useRef()

  const openInputFile = () => {
    inputFileRef.current.click()

    return false
  }

  const handleCardNumber = (value) => {
    setCardNumber(value)
  }

  const handleChangeFiles = (files) => {
    let fetchFiles = []

    for (let file of files)
      fetchFiles.push({ name: file.name, size: file.size })

    setSelectedFiles([...selectedFiles, ...fetchFiles])
    setIsFileSelected(true)
  }

  const handleCancelFile = (name) => {
    setSelectedFiles(selectedFiles.filter((file) => file.name !== name))

    let removedFiles = []

    for (let file of allFiles) {
      if (file.name !== name)
        removedFiles.push(file)
    }

    setAllFiles(removedFiles)
  }

  const handleSubmit = (values) => {
    setLoading(true)
    submit(values)
  }

  const handleHide = () => {
    setAddModal(false)
    setShowThisModal(true)
  }

  const handleCreate = (payload) => {
    setCreate(true)
    createPaymentOption(payload)
    .then((res) => {
      setCreate(false)
      toast.success(res.data)
      setAddModal(false)
      setShowThisModal(true)
      setLoading(true)
      getPaymentOptions()
      .then((res) => {
        const cardNumbers = res.data.map(option => option.payment?.creditCard.cardNumber)
        setOptions(cardNumbers)
        setCardNumber(cardNumbers.length === 0 ? 'No Number' : 'Select Number')
        setLoading(false)
      })
    })
    .catch((error) => {
      const errMsg = getErrorMessage(error)
      toast.error(errMsg)
    })
  }

  useEffect(() => {
    if (status)
      setLoading(false)
  }, [status])

  useEffect(() => {
    setLoading(true)
    setShowThisModal(true)
    setAddModal(false)
    getPaymentOptions()
      .then((res) => {
        const cardNumbers = res.data.map(option => option.payment?.creditCard.cardNumber)
        setOptions(cardNumbers)
        setCardNumber(cardNumbers.length === 0 ? 'No Number' : 'Select Number')
        setLoading(false)
      })
  }, [])

  return (
    <>
      <Modal size='lg'
        show={show}
        onHide={onHide}
        className={`${!showThisModal && 'opacity-0'}`}
        aria-labelledby='example-modal-sizes-title-lg'
      >
        <Modal.Header>
          <Modal.Title>Service Quote-Approval</Modal.Title>
          <span className='text-hover-primary cursor-pointer' onClick={onHide}>
            <KTSVG
              path='/media/icons/duotone/Navigation/Close.svg'
              className='svg-icon-1'
            />
          </span>
        </Modal.Header>
        <Formik
          validationSchema={CustomerEditSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ handleSubmit, handleChange, setFieldValue, errors, touched, values }) => (
            <>
              <Modal.Body className='overlay cursor-default'>
                {loading && (
                  <div className='overlay-layer bg-transparent'>
                    <div className='spinner spinner-lg spinner-success' />
                  </div>
                )}
                <Form className='form form-label-right'>
                  <div className='form-group row'>
                    <div className='col-lg-12'>
                      <span>Service Quote: {quoteNumber}</span>
                    </div>
                    {/* PO */}
                      <div className={`col-lg-6 mt-5 ${values.paymentType !== 'cc' && 'd-none'}`}>
                        <span className='required'>
                          Credit Card Number
                        </span>
                        <SelectDropDown
                          data={options}
                          defaultValue={cardNumber}
                          dirUp={false}
                          selectedValue={(value) => {
                            handleCardNumber(value)
                            setFieldValue('po', value)
                          }}
                        />
                        {touched.po && errors.po ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>Card Number is required</div>
                          </div>
                        ) : null}
                        <div
                          className='mt-2 text-primary text-decoration-underline cursor-pointer'
                          onClick={() => {
                            setShowThisModal(false)
                            setAddModal(true)
                          }}
                        >
                          Create new payment option
                        </div>
                    </div> 
                    <div className={`col-lg-6 mt-5 ${values.paymentType === 'cc' && 'd-none'}`}>
                      <span className='required'>
                        {
                          values.paymentType === 'cc' ? 'Credit Card Number' :
                            values.paymentType === 'po' ? 'Purchase Order Number' : 'Other'
                        }
                      </span>
                      <Field
                        name={
                          values.paymentType === 'cc' ? 'cc' :
                          values.paymentType === 'po' ? 'po' : 'other'
                        }
                        as='input'
                        placeholder={
                          values.paymentType === 'cc' ? 'Enter C.C Number' :
                          values.paymentType === 'po' ? 'Enter P.O Number' : 'Other'
                        }
                        className='form-control'
                      />
                      {touched.po && errors.po ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.po}</div>
                        </div>
                      ) : null}
                    </div>
                    {/* Payment Type */}
                    <div className='col-lg-6 mt-5'>
                      <span className='required'>Payment Option</span>
                      <select
                        className='form-control'
                        name='paymentType'
                        data-control='select2'
                        label='Payment Type'
                        onChange={handleChange}
                      >
                        <option value='po'>Purchase Order</option>
                        <option value='cc'>Credit Card</option>
                        <option value='other'>Other</option>
                      </select>
                    </div>
                  </div>

                  {/* Comment */}
                  <div className='form-group mt-5'>
                    <span className='required'>Approval Comments</span>
                    <Field
                      name='comment'
                      as='textarea'
                      rows='5'
                      placeholder='Enter Approval Comments'
                      className='form-control'
                    />
                    {touched.comment && errors.comment ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{errors.comment}</div>
                      </div>
                    ) : null}
                  </div>

                  {/* Attachment */}
                  <div className='form-group mt-5'>
                    <div className='d-flex justify-content-between'>
                      <span className='required'>Attachment</span>
                      <span className='btn btn-primary btn-elevate p-2' onClick={openInputFile}>
                        <KTSVG
                          path='/media/icons/duotone/Files/UPload-folder.svg'
                          className='svg-icon-2 '
                        />
                        <span>Upload Files</span>
                      </span>
                    </div>
                    <input
                      type='file'
                      name='attachment[]'
                      className='form-control d-none'
                      ref={inputFileRef}
                      onChange={(event) => {
                        let files = event.currentTarget.files
                        let attachedFiles = [...allFiles, ...files]
                        let dt = new DataTransfer()

                        attachedFiles.forEach((file) => {
                          dt.items.add(file)
                        })

                        setFieldValue('attachment', dt.files)
                        setAllFiles(attachedFiles)

                        handleChangeFiles(files)
                      }}
                      multiple
                    />
                    <div className='my-5'>
                      {isfileSelected &&
                        selectedFiles?.map((file, index) => {
                          return (
                            <div key={index} className='d-flex justify-content-between p-3 background-light-dark my-2 cursor-pointer'>
                              <span>{file.name} ({file.size} KB)</span>
                              <KTSVG
                                path='/media/icons/duotone/Navigation/Close.svg'
                                className='svg-icon-1'
                                onClick={() => handleCancelFile(file.name)}
                              />
                            </div>
                          )
                        })
                      }
                    </div>
                    {
                      (isClick && values.paymentType === 'po' && allFiles.length === 0) &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>Attachment is required</div>
                      </div>
                    }
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button type='button'
                  onClick={() => {
                    onHide()
                    setIsFileSelected(false)
                    setSelectedFiles([])
                    setAllFiles([])
                  }}
                  className='btn btn-light btn-elevate'>
                  Cancel
                </button>
                <> </>
                <button
                  type='submit'
                  onClick={() => {
                    setIsClick(true)
                    if (values.po !== '' && values.comment !== '') {
                      if (values.paymentType === 'po' && allFiles.length === 0)
                        return

                      const paymentType = values.paymentType === 'po' ? 'PO' : values.paymentType === 'other' ? 'Other' : 'CC'
                      const CONFIRM_ALERT_CONTENT = CONFIRM_APPROVE_CONTENT.replace('@quoteNumber', quoteNumber).replace('@paymentType', paymentType).replace('@number', values.po)
                      showConfirmAlert(CONFIRM_ALERT_CONTENT, handleSubmit)
                    } else {
                      handleSubmit()
                    }

                  }}
                  className='btn btn-primary btn-elevate'
                  disabled={loading}
                >
                  {!loading && <span className='indicator-label'>Approve</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
      <AddCardModal
        show={showAddModal}
        onHide={handleHide}
        title='Add New Card'
        option={options}
        submit={create}
        onCreate={handleCreate}
      />
    </>
  )
}

export default ApproveModal
