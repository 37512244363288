import clsx from 'clsx'
import React, { FC, useState, useEffect } from 'react'
import {shallowEqual, useSelector} from 'react-redux'

import { KTSVG, toServerUrl} from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { useLayout } from '../../core'
import { RootState } from '../../../../setup'
import { NotificationModel, UserModel } from '../../../../types'
import { HeaderNotifications } from '../../../../app/modules/notifications/HeaderNotifications'

const toolbarButtonClass = {
  space: 'ms-1 ms-lg-3',
  height: 'w-30px h-30px w-md-40px h-md-40px',
  avatarHeight: 'symbol-30px symbol-md-40px',
  icon: 'svg-icon-1'
}

const Topbar: FC = () => {
  const {config} = useLayout()
  const [unReadCount, setUnReadCount] = useState<number>(0)
  
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const notificationsSelector = useSelector<RootState>((state) => state.notifications.notifications) as any

  useEffect(() => {
    let count = 0

    if (notificationsSelector?.notifications?.length !== 0) {
      notificationsSelector?.notifications?.forEach((notification: NotificationModel) => {
        if (!notification.read_at) count++
      })
    }
    setUnReadCount(count)
  }, [notificationsSelector])

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}

      {/* NOTIFICATIONS */}
      <div className={clsx('d-flex align-items-center', toolbarButtonClass.space)}>
        {/* begin::Menu- wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonClass.height
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotone/Communication/Group-chat.svg'
            className={toolbarButtonClass.icon}
          />
          {
            unReadCount !== 0 &&
            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
          }
        </div>
        <HeaderNotifications />
        {/* end::Menu wrapper */}
      </div>

      {/* begin::User */}
      <div className={clsx('d-flex align-items-center', toolbarButtonClass.space)}>
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarButtonClass.height)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={toServerUrl('/media/avatar/' + user.avatar)} alt='metronic' className={toolbarButtonClass.height} />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotone/Text/Toggle-Right.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export {Topbar}
