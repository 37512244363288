import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as salesQuotes from '../../app/pages/sales-quotes/salesQuotesRedux'
import * as calibrationReports from '../../app/pages/calibration-reports/calibrationReportsRedux'
import * as serviceOrders from '../../app/pages/service-orders/serviceOrdersRedux'
import * as salesOrders from '../../app/pages/sales-orders/salesOrdersRedux'
import * as serviceQuotes from '../../app/pages/service-quotes/serviceQuotesRedux'
import * as customers from '../../app/pages/customer/redux/customerRedux'
import * as nbsAdmins from '../../app/pages/nbs-admin/redux/nbsAdminRedux'
import * as assetManagement from '../../app/pages/asset-mangement/AssetManagementRedux'
import * as blogs from '../../app/modules/blog/redux/blogRedux'
import * as notifications from '../../app/modules/notifications/NotificationsRedux'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  salesQuotes: salesQuotes.reducer,
  calibrationReports: calibrationReports.reducer,
  serviceOrders: serviceOrders.reducer,
  salesOrders: salesOrders.reducer,
  serviceQuotes: serviceQuotes.reducer,
  customers: customers.reducer,
  nbsAdmins: nbsAdmins.reducer,
  blogs: blogs.reducer,
  notifications: notifications.reducer,
  assetManagement: assetManagement.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    salesQuotes.saga(),
    calibrationReports.saga(),
    salesOrders.saga(),
    serviceQuotes.saga(),
    serviceOrders.saga(),
    customers.saga(),
    nbsAdmins.saga(),
    blogs.saga(),
    notifications.saga(),
    assetManagement.saga(),
  ])
}
