import { toServerUrl } from "../_metronic/helpers";

export const isImageFile = (fileName: string):boolean => {
    if (!fileName) return false;
    
    const acceptedImageTypes = ['gif', 'jpeg', 'png', 'tif', 'svg', 'pjp', 'xbm', 'jxl', 'svgz', 'jpg', 'ico', 'tiff', 'jfif', 'webp']
    const piece = fileName.split('.')
    const typeOfFile = piece[piece.length - 1]

    return acceptedImageTypes.includes(typeOfFile)
}

export const checkIfImageExists = (url: string) => {
    const img = new Image();
    img.src = url;
  
    if (img.complete) {
        return url;
    } else {
        return toServerUrl('/media/avatar/blank.png');
    }
};
